<template>
  <div>
    <!-- 添加圈舍 -->
    <van-nav-bar
      title="添加圈舍"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <div style="display: flex">
        <div style="margin: 15px; font-size: 14px; color: #646566">
          地图选点
        </div>
        <div
          style="margin: 15px; font-size: 14px; color: #646566"
          @click="Mymap"
        >
          点击这里
        </div>
      </div>
      <van-field
        label="圈舍名称"
        v-model="name"
        placeholder="请输入圈舍名称"
        :rules="[{ required: true, message: '圈舍名称不能为空' }]"
      />
      <van-cell-group class="farmland-unit">
        <van-field
          label="圈舍面积"
          v-model="area"
          placeholder="请输入圈舍面积"
          :rules="[{ required: true, message: '圈舍面积不能为空' }]"
        />
        <div class="unit">平方米</div>
      </van-cell-group>
      <van-field
        label="联系人"
        v-model="linkman"
        placeholder="请输入联系人"
        :rules="[{ required: true, message: '联系人不能为空' }]"
      />
      <van-field
        label="联系电话"
        v-model="linkman_phone"
        placeholder="请输入联系电话"
        :rules="[{ required: true, message: '联系电话不能为空' }]"
      />
      <van-cell-group>
        <van-field label="圈舍照片" />
        <van-uploader multiple v-model="image" :max-count="1" />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >添加圈舍</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { AddEnclosure } from "../../service/Enclosure/Enclosure"
// import { getProvince, getCity, getCountry } from "@/service/areaService.js";
import { getLocation } from "@/service/Addfarmland.js";
import { NavBar, Field, Form, CellGroup, Button, Uploader, Dialog } from "vant"
export default {
  name: "",
  props: {},
  components: {
    VanField: Field,
    VanNavBar: NavBar,
    VanForm: Form,
    VanCellGroup: CellGroup,
    VanButton: Button,
    VanUploader: Uploader
  },
  data() {
    return {
      name: "",
      area: "",
      linkman: "",
      linkman_phone: "",
      image: [],
      lonlat: ""
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    Mymap() {
      this.$store.commit("setname", this.name)
      this.$store.commit("setfarmlandArea", this.area)
      this.$store.commit("setcontactPerson", this.linkman)
      this.$store.commit("setcontactNumber", this.linkman_phone)
      this.$store.commit("setfileList", this.image)
      this.$router.push({
        path: "/Mymap"
      })
    },
    init() {
      this.lonlat = this.$store.state.lng + "," + this.$store.state.lat;
      this.name = this.$store.state.name;
      this.area = this.$store.state.farmlandArea;
      this.linkman = this.$store.state.contactPerson;
      this.linkman_phone = this.$store.state.contactNumber;
      this.image = this.$store.state.fileList;
      if (this.lonlat === "") {
        this.fieldValue = "";
      } else {
        getLocation(this.lonlat).then(res => {
          if (res.data.province === null) {
            res.data.province = "";
          }
          if (res.data.city === null) {
            res.data.city = "";
          }
          if (res.data.county === null) {
            res.data.county = "";
            this.fieldValue = "";
          } else {
            this.fieldValue =
              res.data.province + "/" + res.data.city + "/" + res.data.county;
            this.provinceCode = res.data.provinceCode;
            this.cityCode = res.data.cityCode;
            this.countyCode = res.data.countyCode;
          }
        });
      }
    },
    onSubmit(values) {
      if (values === "") {
        Dialog.confirm({
          title: "提交圈舍表单失败",
          message: "请重新提交"
        }).catch(() => {
          // on cancel
        });
    } else {
      Dialog.confirm({
        title: "提交圈舍表单",
        message: "您确认要提交嘛"
      }).then(() => {
        const param = new FormData();
        param.append("image", this.image[0].file)
        param.append("name", this.name)
        param.append("area", this.area)
        param.append("linkman", this.linkman)
        param.append("linkman_phone", this.linkman_phone)
        param.append('longitude', this.$store.state.lng)
        param.append('latitude', this.$store.state.lat)
        AddEnclosure(param).then(res => {
          if (res.code === 200) {
            this.lonlat = ""
            this.$router.push({
              path: "/mine/Enclosure"
            })
          }
        })
      })
    }
  }
  },
  mounted() {
    this.init()
  },
  watch: {},
  computed: {},
  filters: {}
}
</script>

<style scoped lang='scss'>
.farmland-unit {
  position: relative;
  .unit {
    position: absolute;
    top: 3.66667vw;
    left: 80.33333vw;
  }
}
</style>